body {
  font-family: 'Arial', sans-serif;
  background-color: #1E1E1E;
  color: #FFFFFF;
  margin: 0;
  padding: 20px;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
  color: #1DB954;
}

.container {
  display: flex;
  gap: 20px;
}

.content-panel, .metatags-panel {
  background-color: #2A2A2A;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-panel {
  flex: 2;
}

.metatags-panel {
  flex: 1;
}

h2 {
  font-size: 24px;
  color: #1DB954;
  margin-bottom: 15px;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
}

input, textarea {
  width: 100%;
  padding: 10px;
  background-color: #3E3E3E;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  height: 150px;
  resize: vertical;
}

.copy-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.category-btn {
  padding: 8px 12px;
  background-color: #3E3E3E;
  color: #BBBBBB;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 5px 10px 0;
  transition: all 0.3s ease;
}

.category-btn.active {
  background-color: #1DB954;
  color: #FFFFFF;
}

.metatags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.metatag {
  background-color: #1DB954;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  cursor: move;
  transition: all 0.3s ease;
  position: relative;
}

.metatag:hover {
  background-color: #3E3E3E;
}

.tooltip {
  visibility: hidden;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.metatag:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

#artist-metatags {
  margin-bottom: 20px;
}

.disclaimer {
  margin-top: 20px;
  padding: 15px;
  background-color: #3E3E3E;
  border-radius: 5px;
  font-size: 14px;
  color: #BBBBBB;
  text-align: center;
}

/* Stili per la versione mobile */
@media (max-width: 640px) {
  body {
    padding: 10px;
  }

  .container {
    flex-direction: column;
  }

  h1 {
    font-size: 24px;
  }

  .mobile-container {
    padding: 10px;
  }

  .intro-section {
    background-color: #2A2A2A;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .toggle-intro {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .tab-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .tab {
    flex: 1;
    padding: 10px;
    background-color: #2A2A2A;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  .tab.active {
    background-color: #1DB954;
  }

  .copy-btn {
    position: static;
    width: 100%;
    margin-top: 10px;
  }

  /* Stili per il modale nella versione mobile */
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #2A2A2A;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 300px;
  }

  .modal-button {
    background-color: #1DB954;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    font-size: 1rem;
    width: calc(50% - 20px);
    cursor: pointer;
  }
}